<template>
    <div class="services">
        <div class="row control-padding">
            <div class="col-lg-8 col-md-12 product-wrapper">
                <!-- CTAs -->
                <div class="container-fluid" v-if="!product">
                    <div class="row">
                        <!-- Facebook -->
                        <div class="product-container col-md-6">
                            <card>
                                <div class="product-header">
                                    <img @click="setProduct('facebook')" class="product-image" src="https://spotonmarketing.dk/wp-content/uploads/facebook_topbanner.jpg">

                                    <div class="product">
                                        <span class="fa fa-facebook fa-fw"></span>&nbsp;
                                        <span class="title">Facebook-annoncering</span>
                                    </div>
                                </div>

                                <div class="description" @click="setProduct('facebook')">
                                    Skab opmærksomhed om din virksomhed og flere salg af dine produkter med vores eksperters hjælp til strategi, opsætning og annoncering på verdens største sociale medie
                                </div>

                                <div class="cta text-center">
                                    <a @click="setProduct('facebook')" class="btn btn-primary">Læs mere</a>
                                </div>
                            </card>
                        </div>

                        <!-- Google AdWords -->
                        <div class="product-container col-md-6">
                            <card>
                                <div class="product-header">
                                    <img @click="setProduct('adwords')" class="product-image" src="https://spotonmarketing.dk/wp-content/uploads/adwords_topbanner.jpg">

                                    <div class="product">
                                        <span class="fa fa-google fa-fw"></span>&nbsp;
                                        <span class="title">Google Ads</span>
                                    </div>
                                </div>

                                <div class="description" @click="setProduct('adwords')">
                                    Annoncér i toppen af Google og lad vores digitale eksperter skabe mere trafik og salg til din forretning gennem annoncer på verdens største søgemaskine
                                </div>

                                <div class="cta text-center">
                                    <a @click="setProduct('adwords')" class="btn btn-primary">Læs mere</a>
                                </div>
                            </card>
                        </div>
                    </div>

                    <div class="row">
                        <!-- SEO -->
                        <div class="product-container col-md-6">
                            <card>
                                <div class="product-header">
                                    <img @click="setProduct('seo')" class="product-image" src="https://spotonmarketing.dk/wp-content/uploads/seotop.jpg">

                                    <div class="product">
                                        <span class="fa fa-search fa-fw"></span>&nbsp;
                                        <span class="title">SEO</span>
                                    </div>
                                </div>

                                <div class="description" @click="setProduct('seo')">
                                    Få en placering på første side af Google med hjælp fra vores erfarne SEO-team - og se på når din virksomhed begynder at få mere og mere trafik og salg ved at være synlig, når kunder søger efter din hjælp
                                </div>

                                <div class="cta text-center">
                                    <a @click="setProduct('seo')" class="btn btn-primary">Læs mere</a>
                                </div>
                            </card>
                        </div>

                        <!-- Website -->
                        <div class="product-container col-md-6">
                            <card>
                                <div class="product-header">
                                    <img @click="setProduct('website')" class="product-image" src="https://spotonmarketing.dk/wp-content/uploads/hjemmeside_topbanner.jpg">

                                    <div class="product">
                                        <span class="fa fa-globe fa-fw"></span>&nbsp;
                                        <span class="title">Hjemmeside</span>
                                    </div>
                                </div>

                                <div @click="setProduct('website')" class="description">
                                    Giv et positivt førstehåndsindtryk af din virksomhed og få designet en levende og unik hjemmeside, der får dine kunder til at føle sig velkommen ved første klik
                                </div>

                                <div class="cta text-center">
                                    <a @click="setProduct('website')" class="btn btn-primary">Læs mere</a>
                                </div>
                            </card>
                        </div>
                    </div>
                </div>

                <!-- Extended -->
                <div class="container-fluid">
                    <div class="row">
                        <!-- Facebook -->
                        <div class="product-container product-extended col-md-12" v-if="product === 'facebook'">
                            <card>
                                <div class="product-header">
                                    <img class="product-image" src="https://spotonmarketing.dk/wp-content/uploads/facebook_topbanner.jpg">

                                    <div class="product">
                                        <span class="fa fa-facebook fa-fw"></span>&nbsp;
                                        <span class="title">Facebook-annoncering</span>
                                    </div>
                                </div>

                                <div class="description">

                                        <div class="row detail-container">
                                            <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                                <h4>Annoncer der rammer de mennesker, der vil elske at høre fra dig.</h4>

                                                <p>
                                                    Med annoncer på Facebook kan du reklamere direkte til de mennesker, der har størst mulighed for at blive til dine kunder.
                                                    Du vælger nemlig helt selv, hvilke kunder du vil tale til. Vores team hjælper dig godt i gang og sikkert i mål med dine Facebook-annoncer.
                                                    Vi finder din målgruppe, planlægger dine opslag og fører idéerne ud i livet på dine vegne.
                                                </p>
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                                <h4>Strategi og målsætning</h4>

                                                <p>
                                                    Vi arbejder med Facebook ud fra en målrettet strategi, som vi planlægger sammen med dig. Du er eksperten på din forretning. Og derfor ved du ved bedst, om det er kontaktoplysninger, besøg i webshoppen eller flere henvendelser, der er det mest interessante for dig. Vi sætter realistiske mål ud fra dine interesser – og indfrier dem gennem målrettede annoncer på Facebook.
                                                </p>
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                                <h4>Strategi og målsætning</h4>

                                                <p>
                                                    Vi arbejder med Facebook ud fra en målrettet strategi, som vi planlægger sammen med dig. Du er eksperten på din forretning. Og derfor ved du ved bedst, om det er kontaktoplysninger, besøg i webshoppen eller flere henvendelser, der er det mest interessante for dig. Vi sætter realistiske mål ud fra dine interesser – og indfrier dem gennem målrettede annoncer på Facebook.
                                                </p>
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                                <h4>Udgangspunkt i dine kunder</h4>

                                                <p>
                                                    Med Facebook-annoncer skræddersyer vi din markedsføring – og annoncerer kun til de mennesker, der har det største potentiale for at blive dine næste kunder.
                                                    Vi hjælper dig med at udvælge din målgruppe ud fra alder, bopæl og interesse. Og derefter søsætter vi annoncer, der kun rammer de kunder, der bor i nærheden af din forretning – eller har en interesse i at høre fra dig.
                                                </p>
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                                <h4>Grafisk design og tekstforfatning</h4>

                                                <p>
                                                    Vores team består af mere end 60 ansatte herunder designere, tekstforfattere og specialister i Facebook. Vi har således mange kræfter på holdet, hvilket giver os mulighed for at tilbyde dig en helstøbt løsning. Med andre ord står vi for teksterne, grafikken og teknikken. Alle elementer er udformet kun til din forretning.
                                                    Dine annoncer fremstår således unikke og professionelle på Facebook, hvilket får din virksomhed til at skille sig ud fra dine konkurrenter.
                                                </p>
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                                <h4>Datadreven kampagne</h4>

                                                <p>
                                                    Vi indsamler løbende data om din målgruppe og vurderer effekten af vores annoncering på dine vegne.
                                                    Vores specialister bruger denne viden til at fortælle dig mere om, hvilken befolkningsgruppe der er mest interesseret i at høre fra dig.
                                                    Og vi bruger dataen i vores eget arbejde med at optimere din kampagne, så effekten af din annoncer bliver større og større.
                                                </p>
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                                <h4>Indsamling af oplysninger om dine kunder</h4>

                                                <p>
                                                    Vores team hjælper dig med at opsætte Facebook-annoncer, der kan samle information om dine kunder. Vores hold sammensætter unikke annoncer, hvor dine kunder kan indtaste navn, adresse, telefon, email eller andet.
                                                    Derefter kan du selv bruge oplysningerne i dit eget salgsarbejde – og henvende dig direkte til dine kunder.
                                                </p>
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                                <h4>Annoncer der viser vej hen til din forretning</h4>

                                                <p>
                                                    Vi opsætter og udruller annoncer, der ikke kun reklamerer for din forretning. Men også guider dine kunder hen til din hjemmeside, webshop eller Facebookside. Denne form for annoncer er især gode til at skabe opmærksomhed om din virksomhed.
                                                    Og det er en effektiv metode til at få flere kunder til at klikke sig ind på din webshop – eller gribe deres mobiltelefon og ringe direkte til dig.
                                                </p>
                                            </div>
                                        </div>

                                </div>

                                <div class="cta text-center">
                                    <a @click="setProduct(null)" class="btn btn-primary">Tilbage</a>
                                </div>
                            </card>
                        </div>

                        <!-- Google AdWords -->
                        <div class="product-container product-extended col-md-12" v-if="product === 'adwords'">
                            <card>
                                <div class="product-header">
                                    <img class="product-image" src="https://spotonmarketing.dk/wp-content/uploads/adwords_topbanner.jpg">

                                    <div class="product">
                                        <span class="fa fa-google fa-fw"></span>&nbsp;
                                        <span class="title">Google Ads</span>
                                    </div>
                                </div>

                                <div class="description">
                                    <div class="row detail-container">
                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Den sikre vej til toppen. Og til flere henvendelser.</h4>

                                            <p>
                                                Med Google Ads kan du annoncere i toppen af Google og få flere kunder i en fart.
                                                Du bestemmer selv dine søgeord. Og din målgruppe.
                                                Vores team hjælper dig med at tænke strategisk, så du rammer de rigtige mennesker på det rigtige tidspunkt.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Annoncér i toppen af Google</h4>

                                            <p>
                                                Forestil dig, at dine kunder søger efter en forretning ligesom din. Forestil dig så, at det er din annonce, de ser, når de leder efter information på Google. Kan du se, hvad vi mener? I dag bruger de fleste mennesker søgemaskinen Google til at lede efter informationer.
                                                Vi hjælper din forretning med at stryge til tops på Google. Og sikre, at det kun er kunder fra dit lokalområde eller med en interesse i din forretning, der ser dine annoncer.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Få flere kunder fra dit lokalområde</h4>

                                            <p>
                                                Med annoncer på Google kan vi hjælpe dig med at annoncere til kunder fra et eller flere lokalområder.
                                                Måske vil du gerne række bredt ud og henvende dig til flere geografiske områder?
                                                Eller også vil du gerne snævre din målgruppe ind – og kun henvende dig til dem, der bor tæt på din forretning.
                                                Vores team kan hjælpe dig med det ene, det andet eller begge dele.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Ram dine kunder på det helt rigtige tidspunkt</h4>

                                            <p>
                                                Med vores hjælp får du annoncer på Google, der er skræddersyede til at ramme dine kunder på det helt rigtige tidspunkt.
                                                Det vil sige, at dine annoncer dukker op, lige præcis når dine kunder søger efter din hjælp i dit lokalområde.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Betal kun, når kunden klikker på din annonce</h4>

                                            <p>
                                                Google Ads er faktisk smartere endnu. Du betaler nemlig kun, når kunder klikker på din annonce og bliver ført hen til din hjemmeside. Eller når de ringer til dig direkte fra deres søgning på Google.
                                                Det er markedsføring, der virker – og som kan betale sig.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Google-certificeret markedsføring</h4>

                                            <p>
                                                Vores eksperter er ikke bare gode til Google. De er uddannede af selveste Google.
                                                Vi kan derfor kalde os for certificerede Google-partnere. Og det er din garanti for, at du får en kvalificeret online markedsføring, der virker.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="cta text-center">
                                    <a @click="setProduct(null)" class="btn btn-primary">Tilbage</a>
                                </div>
                            </card>
                        </div>
                    </div>

                    <div class="row">
                        <!-- SEO -->
                        <div class="product-container product-extended col-md-12" v-if="product === 'seo'">
                            <card>
                                <div class="product-header">
                                    <img class="product-image" src="https://spotonmarketing.dk/wp-content/uploads/seotop.jpg">

                                    <div class="product">
                                        <span class="fa fa-search fa-fw"></span>&nbsp;
                                        <span class="title">SEO</span>
                                    </div>
                                </div>

                                <div class="description">
                                    <div class="row detail-container">
                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Byg fundamentet for din online succes. Nu og i fremtiden.</h4>

                                            <p>
                                                Med søgemaskineoptimering kan du sikre dig en topplacering på Google og andre søgemaskiner.
                                                Du investerer i trafik til din hjemmeside, der giver flere køb til din forretning. Både nu.
                                                Og på den lange bane. Vores team hjælper din forretning med at komme i gang med SEO - og med at komme i mål.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Få en topplacering på Google</h4>

                                            <p>
                                                Glem telefonbogen. I dag finder de fleste nemlig deres information på Google. Og derfor er det vigtigt, at din forretning er synlig, når der søges efter en virksomhed som din i dit område.
                                                Med hjælp fra vores specialister kan du få en af de højeste placeringer på Google. Og være til at få øje på næste gang, der er brug for netop din hjælp.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Strategi med et klart mål</h4>

                                            <p>
                                                I samarbejde med vores specialister er du med til at forme den strategi, der skal føre din forretning til tops på Google. Vi begynder med en dialog om, hvilke forretningsområder du ønsker at markedsføre dig inden for.
                                                Her fortæller vores eksperter, hvor de ser den største værdi og mulige fortjeneste for din forretning. Og derefter finder vi i fællesskab de søgeord, vi skal fokusere på i din online markedsføring.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Analyse af styrker og svagheder</h4>

                                            <p>
                                                En succesfuld strategi begynder med en indsigt i din position på markedet. Det samme gælder inden for SEO. Vores eksperter analyserer dine styrker, svagheder, konkurrenter og muligheder inden for dit forretningsområde.
                                                Vi analyserer for eksempel, hvordan din nuværende hjemmeside yder på udvalgte søgeord i forhold til konkurrerende firmaer i dit lokalområde – og således skaber vi en solid grobund for en profitabel SEO-strategi.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Lokal markedsføring</h4>

                                            <p>
                                                De fleste danskere søger efter en ydelse og et lokalområde på Google, når de mangler et produkt eller en service. For eksempel “Tandlæge Nordsjælland”.
                                                Vores specialister får din forretning til at rangere højt på både dit forretningsområde og dit lokalområde, når dine kunder leder efter netop dine ydelser.
                                                Vi sørger også for at gøre din forretning synlig på Googles kort, når en kunde skal finde vej til din forretning. Sådan vinder du kampen om kunderne i dit lokalområde.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Vis adresse, åbningstider og kontaktnummer med Google-profil</h4>

                                            <p>
                                                Med SEO opretter vi en professionel profil til din forretning, der vises i højre side af Google, når kunder søger efter din hjælp. Her kan du informere dine kunder om din adresse, åbningstider, telefonnummer og vise billeder af din forretning.
                                                Din personlige Google-profil er din måde at fremstå mere professionel – og omdanne søgninger til kunder i din forretning.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Tekstforfatning til din hjemmeside</h4>

                                            <p>
                                                Teksterne på din hjemmeside skal fokusere på dine søgeord, hvis du vil stryge til tops på Google. Vores hold består af flere dygtige tekstforfattere, der er specialister i at skrive alle slags webtekster. Det er eksempelvis blogindlæg, forsidetekster, produkttekster eller lokationssider.
                                                Vi kan således hjælpe dig med at skrive nye, professionelle tekster til din forretning såsom blogindlæg – eller omskrive dine nuværende tekster på din hjemmeside.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Få flere anmeldelser fra dine kunder</h4>

                                            <p>
                                                Positive anmeldelser er tit med til at få nye kunder til at vælge din virksomhed frem for dine konkurrenters. Vores team hjælper dig med forskellige redskaber til at få endnu flere af dine glade kunder til at anmelde dig på Trustpilot, Facebook og Google.
                                                Og vi integrerer dine anmeldelser til din Google-profil, så dine positive anmeldelser er det første dine nye kunder ser, når de søger efter din forretning på Google.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="cta text-center">
                                    <a @click="setProduct(null)" class="btn btn-primary">Tilbage</a>
                                </div>
                            </card>
                        </div>

                        <!-- Website -->
                        <div class="product-container product-extended col-md-12" v-if="product === 'website'">
                            <card>
                                <div class="product-header">
                                    <img class="product-image" src="https://spotonmarketing.dk/wp-content/uploads/hjemmeside_topbanner.jpg">

                                    <div class="product">
                                        <span class="fa fa-globe fa-fw"></span>&nbsp;
                                        <span class="title">Hjemmeside</span>
                                    </div>
                                </div>

                                <div class="description">
                                    <div class="row detail-container">
                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>En hjemmeside der får dine kunder til at føle sig velkommen.</h4>

                                            <p>
                                                Med en ny og indbydende hjemmeside kan du sikre dig at dine besøg bliver til henvendelser.
                                                Dine kunder bruger kun fem sekunder på at beslutte om, de vil blive på din side eller gå tilbage til Google.
                                                Du skal fange deres opmærksomhed, inden de forlader dit website. Og det hjælper vi med.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Professionelt og simpelt design</h4>

                                            <p>
                                                I dag besøger dine kunder din hjemmeside inden, de går ned i din forretning.
                                                Med en ny, moderne hjemmeside fremstår du mere professionel og giver et godt indtryk af din virksomhed.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Unikke hjemmesider</h4>

                                            <p>
                                                Din hjemmeside skal afspejle din forretning.
                                                Du kan derfor i fællesskab med vores dygtige designere være med til at udforme din hjemmeside, så den får et personligt og unikt præg, der tiltaler de besøgende.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Giv kunderne en god oplevelse</h4>

                                            <p>
                                                Alle tal viser, at kunder klikker sig væk fra hjemmesider, der er uoverskuelige.
                                                Vi designer din nye hjemmeside i et brugervenligt design, der gør det let for kunderne at navigere rundt på siden og i sidste ende kontakte dig.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Mobile first og responsive design</h4>

                                            <p>
                                                Vi designer din hjemmeside med de nyeste teknikker inden for webdesign, så din hjemmeside fremstår professionel og indbydende fra en mobil, tablet og computer.
                                                Vores designs er mobilvenlige og responsive, hvilket betyder, at din hjemmeside tilpasser sig alle størrelser på skærme.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>Omdan dine besøg til henvendelser</h4>

                                            <p>
                                                Med en ny hjemmeside fra $org('name'), får du lettere ved at omdanne besøg på dit website til henvendelser.
                                                Vi bruger design, kontaktformularer, ring-op-knapper og andre funktioner til at få flere kunder til at ringe dig op.
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 detail-text">
                                            <h4>SpotOn Navigation</h4>

                                            <p>
                                                SpotOn Navigation er vores egen opfindelse.
                                                Når en kunde besøger din hjemmeside fra sin smartphone, kan de med SpotOn Navigation bruge en menubjælke i bunden af dit website med deres tommelfinger, hvilket er lettere for mobilbrugerne.
                                                Her kan kunderne navigere på dit website eller ringe direkte til dig, hvilket vi kan se øger antallet af henvendelser.
                                                Hvis du sidder med din mobiltelefon lige nu, kan du se SpotOn Navigation i bunden på https://spotonmarketing.dk, da vi også bruger det her på vores egen hjemmeside.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="cta text-center">
                                    <a @click="setProduct(null)" class="btn btn-primary">Tilbage</a>
                                </div>
                            </card>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <card>
                    <div class="business-developer">
                        <img src="images/filler/digital-council.jpg" style="object-fit: cover;">
                    </div>

                    <br />

                    <div class="lead text-center" style="font-weight: 600">
                        {{$t('onboarding.requestCall.modal.services.headline')}}
                    </div>

                    <p class="lead text-center" style="font-size: 15px; line-height: 190%;">
                        {{$t('onboarding.requestCall.modal.services.description')}}
                    </p>
                </card>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

    .control-padding {
        padding: 0 15px;
    }

    .product-wrapper {
        padding: 0;
    }

    .btn-override {
        white-space: inherit;
    }

    .detail-container {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        .detail-text {
            padding: 0 30px 20px 30px;
        }
    }

    .services {
        .product-header {
            overflow: hidden;
            position: relative;
            margin: -30px;
            margin-bottom: 25px;
            padding: 0;
            max-height: 120px;
            border-radius: 3px 3px 0 0;

            img {
                max-width: 100%;
            }
        }

        .product-extended {
            .product-header {
                max-height: 170px;
            }
        }

        .product-container {
            cursor: pointer;
        }

        .description {
            p {
                max-width: 600px;
                font-size: 14px;
                line-height: 22px;
            }
        }

        .product {
            position: absolute;
            left: 25px;
            bottom: 25px;
            color: #fff;
            font-size: 24px;

            .title {
                color: inherit;
            }
        }

        .cta {
            margin-top: 20px;
        }

        section {
            margin-bottom: 35px;

            strong {
                display: block;
                color: #d9745d;
                font-weight: 400;
                letter-spacing: 0.5px;
                font-size: 20px;
                margin-bottom: 15px;
            }

            p {
                font-size: 15px;
                line-height: 180%;
            }
        }

        .business-developer {
            overflow: hidden;
            height: 300px;
            text-align: center;

            > img {
                width: 100%;
                min-width: 250px;
            }
        }
    }

    @media screen and (min-width: 1450px) {
        .detail-text {
            width: 50%;
        }
    }
</style>

<script>
import MixpanelService from '@/services/mixpanel/MixpanelService'

export default {
    data() {
        return {
            product: null,
        }
    },

    computed: {
        organizationBackgroundColor() {
            return {backgroundColor: $org('colors.standard.secondary.hex')}
        }
    },

    methods: {
        setProduct(product) {
            if (product !== null) {
                MixpanelService.track('Services - Product - Read More', {'product': product})
            }

            this.product = product
        }
    }
}
</script>
